/*!
 *  FORM
 */

input:-webkit-autofill,
input:-webkit-autofill:focus textarea:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px $institutional inset !important;
  background-clip: content-box;
  -webkit-text-fill-color: $black;
}

::-webkit-input-placeholder {
  font-weight: normal;
  color: $black;
}

::-moz-placeholder {
  font-weight: normal;
  color: $black;
}

:-ms-input-placeholder {
  font-weight: normal;
  color: $black;
}

:-moz-placeholder {
  font-weight: normal;
  color: $black;
}

form {
  width: 100%;

  &#search .form-group {
    &.radio-typologies {
      text-transform: capitalize;
    }

    span.error {
      color: $institutional;
    }
  }

  &#panel_search .form-group,
  &#request_data .form-group {
    &.radio-typologies {
      text-transform: capitalize;
    }

    #typology_panel-error {
      top: 60px !important;
    }
  }

  .form-group {
    position: relative;
    margin-bottom: 35px;

    input {
      width: 100%;
      height: 40px;
      background-color: transparent;
      border-bottom: 1px solid $black;
      color: $black;
      padding: 0 15px 0 0;
      font-size: $rem-18px;
      font-weight: normal !important;

      @include border-radius(0);

      &[type="password"] {
        font-size: $rem-38px;
        letter-spacing: 0.8px;
      }
    }

    span.error {
      font-size: $rem-14px;
      color: $white;
      position: absolute;
      left: 0;
      top: 45px;
    }

    span#usr-error,
    span#pwd-error {
      width: 100%;
      position: absolute;
      left: 0;
      top: 45px;
      color: $error;
    }

    label.label-input {
      font-size: $rem-17px;
      color: $black;
      position: absolute;
      pointer-events: none;
      left: 0px;
      top: 5px;

      @include transition(all 0.2s ease-in-out);
    }

    input:focus ~ label.label-input, input:valid ~ label.label-input {
      font-size: $rem-14px;
      color: $black;
      top: -15px;
    }

    &.form-group-big {
      input {
        font-size: $rem-24px;
      }

      label.label-input {
        font-size: $rem-26px;
        top: 0;
      }

      input:focus ~ label.label-input, input:valid ~ label.label-input {
        font-size: $rem-17px;
        top: -25px;
      }
    }

    &.style-white {
      input {
        border-bottom: 1px solid $white;
        color: $white;
      }

      label.label-input {
        color: $white;
      }

      input:focus ~ label.label-input, input:valid ~ label.label-input {
        color: $white;
      }
    }
  }

  &#search .form-group,
  &#panel_search .form-group {
    label.label-input {
      @media (max-width: 374px) {
        width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

}

// selectFx
.cs-select {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background-color: $white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 100;

  &:focus {
    outline: none;
  }

  select {
    opacity: 0;
    position: absolute;
    left: -9999px;
  }

  span {
    display: block;
    position: relative;
    cursor: pointer;
    color: $black;
    padding: 12px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &> span {
    padding-right: 3em;
  }

  &> span::after, & span::after {
    speak: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &> span::after {
    content: '';
    right: 1em;
  }

  span::after {
    content: '';
    margin-left: 1em;
  }

  &.cs-active {
    z-index: 200;

    .cs-options {
      visibility: visible;
    }
  }

  &.cs-active > span::after {
    width: 30px;
    height: 30px;
    background-image: url('/images/icons/arrow-down.svg');
    background-repeat: no-repeat;
    top: 29px;
    right: 5px;
  }

  .cs-options {
    position: absolute;
    overflow: hidden;
    width: 100%;
    background-color: $white;
    visibility: hidden;
  }

  ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    span {
      padding: 1em;
    }

    li.cs-focus span {
      background-color: $black;
    }

    li.cs-optgroup ul {
      padding-left: 1em;
    }

    li.cs-optgroup > span {
      cursor: default;
    }
  }
}

.cs-skin-border {
  background: transparent;

  ul span::after {
    content: '';
    opacity: 0;
  }

  &> span {
    font-size: $rem-17px;
    border-bottom: 1px solid $black;
    border-color: inherit;
    -webkit-transition: background 0.2s, border-color 0.2s;
    transition: background 0.2s, border-color 0.2s;
  }
}

.cs-skin-border > span::after,
.cs-skin-border .cs-selected span::after {
  width: 30px;
  height: 30px;
  background-image: url('/images/icons/arrow-up.svg');
  background-repeat: no-repeat;
  top: 29px;
  right: 5px;
}

.cs-skin-border .cs-selected span::after {
  width: 30px;
  height: 30px;
  background-image: url('/images/icons/check.svg');
  background-repeat: no-repeat;
  top: 24px;
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.cs-skin-border.cs-active > span {
  background-color: transparent;
  border-color: $black;
  color: $black;
}

.cs-skin-border .cs-options {
  color: $white;
  opacity: 0;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

.cs-skin-border.cs-active .cs-options {
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.cs-skin-border ul span {
  padding: 1em 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  @include media-breakpoint-down(sm) {
    font-size: $rem-15px;
    padding: 0.8em 0;
  }
}

.cs-skin-border .cs-options li span:hover {
  color: lighten($black, 50%);
}

// input radio
.radio-button {
  input {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;

    &+ label.label-radio {
      margin-right: 15px;
      padding: 10px 46px;
      position: relative;
      display: inline-block;
      background-color: $black;
      color: $white;
      text-align: center;
      cursor: pointer;
      transition: color .25s ease-out,  background-color .15s ease-out;

      @media (max-width: 374px) {
        margin-right: 5px;
      }
    }

    &+ label.label-radio.list {
      margin-right: 0 !important;
      padding: 0 !important;
      background-color: transparent;
      color: $black;
      font-weight: 600;
      white-space: nowrap;

      @include media-breakpoint-down(sm) {
        margin-right: 15px !important;
        padding: 10px 10px !important;
        background-color: $black !important;
        color: $white !important;
        font-weight: 400 !important;
      }
    }
  }

  &.checked-institutional {
    input:checked + label.label-radio, .toggle input:focus + label.label-radio {
      background-color: $institutional;
      color: $white;
      z-index: 1;
    }

    input:checked + label.label-radio.list, .toggle input:focus + label.label-radio.list {
      background-color: transparent !important;
      color: $institutional !important;

      @include media-breakpoint-down(sm) {
        background-color: $institutional !important;
        color: $white !important;
      }
    }
  }

  &.checked-white {
    input:checked + label.label-radio, .toggle input:focus + label.label-radio {
      background-color: $white;
      color: $institutional;
      z-index: 1;
    }
  }
}

// newsletter
#mc_embed_signup {
  form {
    padding: 0 !important;

    .mc-field-group {
      width: 100%;
      padding-bottom: 0;
      min-height: auto;

      input {
        padding: 0 15px 0 0;
        text-indent: 0;
        border: none;
        border-bottom: 1px solid $white;

        @include border-radius(0);

        &.mce_inline_error {
          border: none !important;
          border-bottom: 1px solid $white !important;
        }
      }
    }

    #mce-responses {
      width: 100%;
      float: left;
      position: relative;
      top: -15px;
      padding: 0;
      overflow: initial;
      margin: 0;
      clear: initial;

      .response {
        width: 100%;
        margin: 0;
        padding: 0;
        font-weight: normal;
        float: initial;
        top: 0;
      }

      #mce-success-response {
        color: $success;
      }

      #mce-error-response {
        color: $error;
      }
    }
  }

  #mc-embedded-subscribe-form div.mce_inline_error {
    margin: 0;
    padding: 0;
    background-color: transparent;
    font-size: $rem-15px;
    color: $error;
    position: relative;
    top: 15px;

    @include border-radius(0);
  }
}
