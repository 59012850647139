/*!
  *  EXTENSION
*/

.extend-background-cover {
  width: 100%;
  background-repeat: repeat;
  background-position: center center;

  @include background-size(cover);
}

.extend-block-square {
  height: 201px;
  padding: 30px 30px;
  position: relative;

  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }

  h4, h5, h6 {
    position: relative;
    top: 0;

    @include transition(top 0.3s ease-in-out);
  }

  a.link-anchor {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    text-indent: -9999px;
  }

  figure.icon-square {
    width: auto;
    position: absolute;
    right: 30px;
    bottom: 30px;

    @include transition(right 0.3s ease-in-out);
  }

  &:hover {
    cursor: pointer;

    h4, h5, h6 {
      top: 10px;
    }

    figure.icon-square {
      right: 20px;
    }
  }
}

.extend-item-property {
  position: relative;

  .item-property {
    bdi.code-rif {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      white-space: nowrap;
      overflow: hidden;
      position: absolute;
      top: -30px;
      left: -30px;

      @include transform(rotate(-180deg));
    }

    figure.img-property {
      height: 450px;

      @extend .extend-background-cover;
    }

    hgroup.caption {
      display: inline-block;
      padding: 30px 40px;
      margin-top: -40px;
      margin-left: -60px;
      background-color: $white;
      position: relative;

      @include transition(all 0.2s ease-in-out);

      @include media-breakpoint-down(lg) {
        width: 95%;
        padding: 20px 30px;
        margin-left: -40px;
      }

      h4 {
        @include media-breakpoint-down(lg) {
          font-size: $rem-22px;
        }
      }

      h6 {
        span.city {
          @include media-breakpoint-down(lg) {
            display: block;
          }
        }
      }

      a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        text-indent: -99999px;
      }

      &:hover {
        cursor: pointer;
        background-color: $institutional;

        h4 {
          color: $white;
        }
      }
    }
  }
}
