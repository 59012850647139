///*** IMPORT DEPENDENCIES NPM ***///
@import 'node_modules/normalize.css/normalize';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/animate.css/animate';
@import 'node_modules/swiper/dist/css/swiper';
@import 'node_modules/lightgallery/dist/css/lightgallery';

// Import Modules

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

@font-face {
  font-family: 'Moderat';
  src: url('../fonts/Moderat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Moderat';
  src: url('../fonts/Moderat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

///*** VARIABLES ***///
@import "addons/commons/variables";

///*** MIXIN ***///
@import "mixins/mixins";

///*** EXTENSION ***///
@import "extensions/extensions";

///*** GLOBAL ***///
@import "addons/commons/global";

///*** HEADER ***///
@import "addons/layouts/header";

///*** COMPONENTS ***///
@import "addons/components/button";
@import "addons/components/form";
@import "addons/components/panel";
@import "addons/components/modal";
@import "addons/components/typography";

///*** WRAPPER PAGES ***///
@import "addons/layouts/pages";

///*** FOOTER ***///
@import "addons/layouts/footer";
