/*!
  *  FOOTER
*/

footer {
  width: 100%;
  height: auto;
  background-color: $white;
  margin: 100px 0 20px 0;

  @include media-breakpoint-down(sm) {
    margin: 80px 0 20px 0;
  }

  @include media-breakpoint-down(sm) {
    margin: 0;
    padding-bottom: 30px;
  }

  .copy-block {
    margin-top: 100px;

    @include media-breakpoint-down(sm) {
      margin-top: 80px;
    }

    nav.privacy-link {
      span {
        margin: 0 5px;

        &:last-child() {
          margin-right: 0;
        }

        &:hover,
        &:focus {
          cursor: pointer;
          color: $institutional;

          @include transition(color 0.2s ease-in-out);
        }
      }
    }
  }
}
