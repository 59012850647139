/*!
  *  HEADER
*/

header {
  width: 100%;
  height: auto;
  padding: 20px 0;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  figure.trademark {
    max-width: 205px;
  }

  nav.primary-menu {
    a {
      margin-right: 15px;
      font-weight: bold;
      color: $black;

      &:last-child() {
        margin-right: 0;
      }

      &:hover,
      &:focus,
      &.active {
        color: $institutional;
      }
    }
  }

  nav.primary-menu-ex {
    span {
      font-weight: bold;
      color: $black;

      @include transition(color 0.2s ease-in-out);

      &:hover {
        cursor: pointer;
        color: $institutional;
      }
    }
  }

  &.login {
    nav.primary-menu-ex {
      span {
        &:hover {
          cursor: pointer;
          color: $white;
        }
      }
    }
  }
}
