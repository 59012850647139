/*!
  *  MODAL
*/

.modal {
  .modal-body {
    .content-text {
      p {
        font-size: $rem-18px;
      }
    }
  }
}
