/*!
  *  GLOBAL
*/

html {
  overflow-x: hidden;
  font-size: 100%;

  &.no-overflow {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

body,
html {
  height: auto;
  min-height: 100%;
  font-size: 100%;
  overflow-x: hidden;

  @include media-breakpoint-down(sm) {
    font-size: 95%;
  }
}

body {
  font-family: $baseTypography;
  margin: 0;
  padding: 0;
  background-color: $white;
  color: $black;
  line-height: 1.5em;

  @include text-size-adjust(none);
}
/* ---------------------------- Selections ----------------------------------- */
::-moz-selection {
  background: $institutional;
  color: $white;
}

::selection {
  background: $institutional;
  color: $white;
}
/* ---------------------------- Reset ----------------------------------- */
figure {
  width: 100%;
  margin: 0;
}

.notransition {
  @include transition(none !important);
}

* {
  margin: 0;
  padding: 0;
  text-shadow: none;
  outline: medium none !important;
}

*::after,
*::before {
  margin: 0;
  padding: 0;

  @include box-sizing(border-box);
}

a:focus, a:hover {
  text-decoration: none;
}

.clear-both {
  clear: both;
}

.reset-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.full-width-height {
  width: 100%;
  height: 100%;
}

input {
  border: none;
  outline: none;
}

img {
  max-width: 100%;
}

/* ---------------------------- Basic components ----------------------------------- */
.spinner {
  margin-top: 60px;

  .sk-chase {
    width: 35px;
    height: 35px;
    margin: auto;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;

    .sk-chase-dot {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      animation: sk-chase-dot 2.0s infinite ease-in-out both;

      &::before {
        content: '';
        display: block;
        width: 25%;
        height: 25%;
        background-color: #fff;
        border-radius: 100%;
        animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
      }

      &:nth-child(1) { animation-delay: -1.1s; }
      &:nth-child(2) { animation-delay: -1.0s; }
      &:nth-child(3) { animation-delay: -0.9s; }
      &:nth-child(4) { animation-delay: -0.8s; }
      &:nth-child(5) { animation-delay: -0.7s; }
      &:nth-child(6) { animation-delay: -0.6s; }
      &:nth-child(1):before { animation-delay: -1.1s; }
      &:nth-child(2):before { animation-delay: -1.0s; }
      &:nth-child(3):before { animation-delay: -0.9s; }
      &:nth-child(4):before { animation-delay: -0.8s; }
      &:nth-child(5):before { animation-delay: -0.7s; }
      &:nth-child(6):before { animation-delay: -0.6s; }
    }
  }
}

@keyframes sk-chase {
  100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($white, 0.7);
  z-index: 3;

  @include transition(all 0.3s ease-in-out);
}

.square-search {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: fixed;
  top: 120px;
  left: 0;
  background-color: $institutional;
  text-align: center;
  color: $white;
  cursor: pointer;
  z-index: 3;

  @include transition(background-color 0.2s ease-in-out);

  &:hover {
    background-color: lighten($institutional, 6%);
  }
}

.rect-social {
  width: auto;
  height: auto;
  padding: 30px 10px;
  background-color: $black;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;

  nav.icons-social {
    a {
      margin-bottom: 15px;
      display: block;
      color: $white;

      @include transition(color 0.1s ease-in-out);

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: $institutional;
      }
    }
  }
}

.scroll-down {
  opacity: 1;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  z-index: 9;

  @include transition(opacity 0.2s ease-in-out);

  img {
    position: relative;
    top: 0;

    @include transition(top 0.2s ease-in-out);
  }

  &:hover {
    img {
      top: 7px;
    }
  }
}

.scroll-up {
  img {
    position: relative;
    top: 0;

    @include transition(top 0.2s ease-in-out);
  }

  &:hover {
    img {
      top: -7px;
    }
  }
}

bdi {
  &.toast-sq {
    width: auto;
    height: 40px;
    line-height: 40px;
    background-color: $black;
    padding: 0 30px;
    position: fixed;
    text-align: center;
    animation-duration: 0.5s;
    z-index: 9;

    @include media-breakpoint-down(sm) {
      width: calc(100% - 30px);
      height: auto;
      line-height: initial;
      padding: 15px 30px;
    }

    &.top-left {
      top: 30px;
      left: 60px;

      @include media-breakpoint-down(sm) {
        top: 15px;
        left: 15px;
      }
    }

    &.top-right {
      top: 30px;
      right: 60px;

      @include media-breakpoint-down(sm) {
        top: 15px;
        right: 15px;
      }
    }

    &.bottom-left {
      bottom: 30px;
      left: 60px;

      @include media-breakpoint-down(sm) {
        bottom: 15px;
        left: 15px;
      }
    }

    &.bottom-right {
      bottom: 30px;
      right: 60px;

      @include media-breakpoint-down(sm) {
        bottom: 15px;
        right: 15px;
      }
    }

    span {
      font-size: $rem-13px;
      text-transform: uppercase;
      color: $white;
    }
  }
}

aside.bottom-bar-mobile {
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  z-index: 2;

  @include transition(bottom 0.3s ease-in-out);

  &.up {
    bottom: 0 !important;
  }

  &.down {
    bottom: -45px !important;
  }

  .btnn-bottom {
    padding: 10px 0;
    position: relative;

    .link-anchor {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-indent: -9999px;
    }
  }
}

hr.line {
  width: 90px;
  border-width: 2px;
  margin: 30px 0 0 auto;

  &.line-full {
    width: 100% !important;
  }
}

.swiper-container {
  overflow: inherit;

  .swiper-slide {
    cursor: move;

    figure.img-gallery {
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;

      @include background-size(cover);
    }
  }
}

nav.pagination-custom {
  span {
    font-size: $rem-26px;
    color: $black;
    display: inline-block;
    margin-right: 30px;
    cursor: pointer;

    &:hover {
      opacity: 0.6 !important;
    }

    &.active {
      font-size: $rem-40px;
      font-weight: bold;
      color: $institutional;

      &:hover {
        opacity: 1 !important;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

svg {
  stroke-width: 1;

  &.fill-whishlist {
    fill: $black;
  }
}

.cursor-pointer {
  cursor: pointer;
}

// popover
.popover {
  @include border-radius(0);

  .popover-body {
    font-size: $rem-13px;
    color: $black;
    padding: 15px;
  }
}

// modal
// .modal {
//
//   .modal-title {
//     font-weight: 400;
//     color: $black_dark;
//   }
//
//   .text-modal {
//     color: $grey2;
//     font-size: $rem-16px;
//     line-height: 1.6em;
//
//     a {
//       color: $black_dark;
//
//       &:hover {
//         text-decoration: underline;
//       }
//     }
//   }
//
//   ul {
//     margin-left: 20px;
//   }
// }

/* ---------------------------- Colors ----------------------------------- */
.cl-institutional {
  color: $institutional;
}

.cl-black {
  color: $black;
}

.cl-white {
  color: $white;
}

.cl-success {
  color: $success;
}

.cl-alert {
  color: $alert;
}

.cl-error {
  color: $error;
}

.bg-institutional {
  background-color: $institutional;
}

.bg-black {
  background-color: $black;
}

.bg-white {
  background-color: $white;
}

.bg-success {
  background-color: $success;
}

.bg-alert {
  background-color: $alert;
}

.bg-error {
  background-color: $error;
}

.br-institutional {
  border-color: $institutional;
}

.br-black {
  border-color: $black;
}

.br-white {
  border-color: $white;
}

// energetic class
.bg-eg-a {
  background-color: $eg-a;
}

.bg-eg-b {
  background-color: $eg-b;
}

.bg-eg-c {
  background-color: $eg-c;
}

.bg-eg-d {
  background-color: $eg-d;
}

.bg-eg-e {
  background-color: $eg-e;
}

.bg-eg-f {
  background-color: $eg-f;
}

.bg-eg-g {
  background-color: $eg-g;
}

/* ---------------------------- Width ----------------------------------- */
.vw-100 {
  width: 100vw;
}

.vh-100 {
  height: 100vh;
}

.w-80 {
  width: 80%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.w-60 {
  width: 60%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.w-50 {
  width: 50%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

/* ---------------------------- Spacing ----------------------------------- */
.separate-extra-extra-small-tb {
  margin: 15px 0;
}

.separate-extra-extra-small-t {
  margin-top: 15px;
}

.separate-extra-extra-small-b {
  margin-bottom: 15px;
}

.separate-extra-small-tb {
  margin: 30px 0;
}

.separate-extra-small-t {
  margin-top: 30px;
}

.separate-extra-small-b {
  margin-bottom: 30px;
}

.separate-small-tb {
  margin: 60px 0;
}

.separate-small-t {
  margin-top: 60px;
}

.separate-small-b {
  margin-bottom: 60px;
}

.separate-medium-tb {
  margin: 100px 0;
}

.separate-medium-t {
  margin-top: 100px;
}

.separate-medium-b {
  margin-bottom: 100px;
}

.separate-large-tb {
  margin: 140px 0;
}

.separate-large-t {
  margin-top: 140px;
}

.separate-large-b {
  margin-bottom: 140px;
}

.separate-big-tb {
  margin: 240px 0;
}

.separate-big-t {
  margin-top: 240px;
}

.separate-big-b {
  margin-bottom: 240px;
}

.pl-35px {
  padding-left: 35px;
}

.pr-35px {
  padding-right: 35px;
}

/* ---------------------------- Animations ----------------------------------- */
.delay0 {
  -webkit-animation-delay: -0.1s;
  animation-delay: 0.1s;
}

.delay {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay1 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay2 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay3 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.delay4 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay5 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.delay6 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.delay7 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.delay8 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.delay9 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

.delay10 {
  -webkit-animation-delay: 2.2s;
  animation-delay: 2.2s;
}

.delay11 {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

.delay12 {
  -webkit-animation-delay: 2.6s;
  animation-delay: 2.6s;
}

.delay13 {
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}

.delay14 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.delay15 {
  -webkit-animation-delay: 3.2s;
  animation-delay: 3.2s;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
