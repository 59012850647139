/*!
  *  PAGES
*/

section {
  width: 100%;
  height: auto;

  &.splash {
    opacity: 1;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;

    @include transition(opacity 0.6s ease-out);

    figure.trademark {
      max-width: 370px;
      margin: auto;

      @include media-breakpoint-down(sm) {
        max-width: 80%;
      }
    }

    h3 {
      @include media-breakpoint-down(sm) {
        width: 80%;
        margin: auto;
      }
    }

    h5 {
      @include media-breakpoint-down(sm) {
        font-size: $rem-16px !important;
      }
    }
  }

  &.newsletter {
    padding: 100px 0;

    @include media-breakpoint-down(lg) {
      padding: 80px 0;
    }
  }
}

main {
  width: 100%;
  height: auto;
  background-color: $white;
  position: relative;
  z-index: 1;

  // home
  &.home {
    section.hero {
      margin-top: 200px;

      @include media-breakpoint-down(lg) {
        margin-top: 150px;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 130px;
      }

      .slider-featured-home {
        margin-top: 150px;
        padding-left: 30px;
        position: relative;

        hr.line {
          width: 70px;
          margin: 0;
          position: absolute;
          right: 100px;
          top: -30px;
          border-width: 1px;
        }

        .swiper-pagination {
          width: 30%;
          height: 50px;
          position: absolute;
          top: -50px;
          right: 15px;
          left: initial;
          text-align: right;
          z-index: 1;

          span {
            font-size: $rem-22px;
            margin: 0 3px;

            &:last-child {
              margin-right: 0;
            }

            &.swiper-pagination-current {
              font-size: $rem-22px * 2;
              color: $institutional;
            }
          }
        }

        .swiper-controls {
          position: absolute;
          bottom: 182px;
          right: 0;
          z-index: 1;

          .swiper-prev,
          .swiper-next {
            width: 70px;
            height: 70px;
            line-height: 70px;
            background-color: $white;
            text-align: center;

            img {
              width: 30px;
              height: 30px;
            }

            &:hover {
              cursor: pointer;
              background-color: $institutional;
            }
          }
        }

        .swiper-slider-featured-home {
          @extend .extend-item-property;

          .item-property {
            margin-bottom: 100px;

            figure.img-property {
              @include media-breakpoint-down(lg) {
                height: 400px !important;
              }
            }

            hgroup.caption {
              @include media-breakpoint-down(lg) {
                width: 60%;
              }
            }
          }
        }
      }

      .scroll-down {
        @include media-breakpoint-down(sm) {
          display: none !important;
        }
      }
    }

    section.services {
      padding: 100px 0;

      @include media-breakpoint-down(lg) {
        padding: 80px 0;
      }

      @include media-breakpoint-down(md) {
        padding: 80px 0 40px 0;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 0;
        padding: 60px 0 80px 0;
      }

      .block-icons {
        @include media-breakpoint-down(md) {
          margin-top: 80px;
        }

        .col-md-6 {
          &:last-child {
            figure.icon-service {
              @include media-breakpoint-down(sm) {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      figure.icon-service {
        margin-bottom: 60px;

        img {
          width: auto;
          height: 70px;
        }

        figcaption {
          margin-top: 20px;
          font-size: $rem-18px;
          line-height: 1.5em;

          @include media-breakpoint-down(sm) {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    section.banner-data-request {
      padding: 100px 0;

      @include media-breakpoint-down(lg) {
        padding: 80px 0;
      }

      @include media-breakpoint-down(sm) {
        padding: 80px 0 50px 0;
      }

      .block-square {
        @extend .extend-block-square;
      }
    }

    section.quote {
      padding: 150px 0;

      @include media-breakpoint-down(lg) {
        padding: 100px 0;
      }

      @include media-breakpoint-down(sm) {
        padding: 80px 0;
      }

      blockquote {
        margin: 0 !important;
        padding: 0 !important;
        border: none !important;

        img {
          width: 32px;
          height: 32px;
          position: relative;
          left: -42px;
          margin-bottom: 15px;

          @include media-breakpoint-down(md) {
            left: 0;
          }
        }
      }
    }
  }

  // rent, sale and search
  &.rent,
  &.sale,
  &.search {
    section.hero {
      margin: 110px 0 140px 0;

      @include media-breakpoint-down(lg) {
        margin: 150px 0;
      }

      @include media-breakpoint-down(sm) {
        margin: 130px 0 40px 0;
      }

      .scroll-down {
        @include media-breakpoint-down(sm) {
          display: none !important;
        }
      }

      .num-properties {
        margin-top: 100px;

        @include media-breakpoint-down(sm) {
          margin-top: 90px;
        }

        abbr {
          font-size: $rem-38px * 6;
          color: $institutional;

          @include media-breakpoint-down(sm) {
            font-size: $rem-34px * 6;
          }
        }

        span {
          font-size: 1rem;
          display: block;
          line-height: 1.2em;
          font-weight: bold;
        }
      }

      form#filter-properties {
        margin-top: 100px;

        @include media-breakpoint-down(sm) {
          margin-top: 40px;
        }

        span.reset {
          display: none;

          abbr.separator {
            cursor: default;
          }

          span.link-reset {
            text-decoration: underline;
            color: $institutional;

            @include transition(color 0.2s ease-in-out);

            &:hover {
              cursor: pointer;
              color: $black;
            }
          }
        }

        button {
          background-color: transparent;
        }

        .block-categories {
          @include media-breakpoint-down(sm) {
            flex-wrap: nowrap;
            overflow-x: scroll;
          }

          .form-group {
            margin-bottom: 0 !important;

            @include media-breakpoint-down(sm) {
              margin-bottom: 15px !important;
            }

            &.radio-button input + label.label-radio {
              width: 100%;
              padding: 15px 0;

              @include media-breakpoint-down(sm) {
                padding: 15px 0;
              }
            }
          }
        }
      }
    }

    section.list-properties {
      @include media-breakpoint-down(sm) {
        margin-top: 80px;
      }

      @extend .extend-item-property;

      .item-property {
        margin-bottom: 100px;

        @include media-breakpoint-down(sm) {
          margin-bottom: 60px;
        }

        bdi.code-rif {
          @include media-breakpoint-down(sm) {
            left: 10px;
            top: -25px;
          }
        }

        figure.img-property {
          height: 350px !important;

          @include media-breakpoint-down(lg) {
            height: 270px !important;
          }

          @include media-breakpoint-down(md) {
            height: 330px !important;
          }

          @include media-breakpoint-down(sm) {
            width: 90%;
            height: 250px !important;
            float: right;
          }
        }

        hgroup.caption {
          @include media-breakpoint-down(sm) {
            padding: 25px 20px;
            margin-left: 0;
          }
        }
      }

      .block-pagination {
        margin-bottom: 100px;

        @include media-breakpoint-down(sm) {
          margin-bottom: 80px;
        }

        figure .link-icon-arrow.disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
  }

  // agency
  &.agency {
    section.hero {
      margin: 160px 0 140px 0;

      @include media-breakpoint-down(lg) {
        margin: 150px 0 120px 0;
      }

      @include media-breakpoint-down(md) {
        margin: 150px 0 60px 0;
      }

      @include media-breakpoint-down(sm) {
        margin: 130px 0 40px 0;
      }

      .scroll-down {
        @include media-breakpoint-down(sm) {
          display: none !important;
        }
      }

      figure {
        @extend .extend-background-cover;

        &.img-01 {
          height: 400px;

          @include media-breakpoint-down(lg) {
            height: 300px;
          }

          @include media-breakpoint-down(sm) {
            height: 250px;
          }
        }

        &.img-02 {
          height: 300px;
          margin-top: -130px;

          @include media-breakpoint-down(lg) {
            height: 200px;
          }
        }
      }
    }

    section.info-agency {
      .block-strengths {
        margin-top: 100px;
        margin-bottom: 100px;

        @include media-breakpoint-down(sm) {
          margin-top: 60px;
          margin-bottom: 60px;
        }

        .block-square {
          height: 300px;
          padding: 40px;

          abbr.num {
            width: 38px;
            height: 38px;
            background-color: white;
            display: inline-block;
            border-radius: 50%;
            text-align: center;
            line-height: 38px;
            color: red;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }
    }

    section.statistics {
      .block-statistics {
        margin-left: 0;
        padding: 30px 0;
      }
    }

    section.where-we-operate {
      margin: 100px 0;

      @include media-breakpoint-down(sm) {
        margin: 80px 0;
      }

      .slider-city {
        position: relative;

        hr.line {
          width: 70px;
          margin: 0;
          position: absolute;
          right: 100px;
          top: 66px;
          border-width: 1px;
        }

        .swiper-pagination {
          width: 30%;
          height: 50px;
          position: absolute;
          top: 50px;
          right: 15px;
          left: initial;
          text-align: right;
          z-index: 1;

          span {
            font-size: $rem-22px;
            margin: 0 3px;

            &:last-child {
              margin-right: 0;
            }

            &.swiper-pagination-current {
              font-size: $rem-22px * 2;
              color: $institutional;
            }
          }
        }

        .swiper-controls {
          .swiper-prev,
          .swiper-next {
            width: 60px;
            height: 35px;
            line-height: 35px;
            text-align: center;

            img {
              width: 35px;
              height: 35px;
            }

            &:hover {
              cursor: pointer;

              img {
                opacity: 0.6;
              }
            }
          }
        }

        .swiper-slider-city {
          margin-top: 100px;

          .swiper-slide {
            figure.img-slide {
              height: 270px;

              @include media-breakpoint-down(lg) {
                height: 200px;
              }

              @extend .extend-background-cover;
            }

            hgroup.caption {
              width: 50%;
              display: block;
              padding: 15px 30px;
              margin-top: -30px;
              margin-left: 30px;
              background-color: $white;

              @include media-breakpoint-down(lg) {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }

  // single-property
  &.single-property {
    hr.line {
      margin: 15px 0 0 auto;
    }

    section.hero {
      margin: 200px 0 100px 0;

      @include media-breakpoint-down(lg) {
        margin: 150px 0 100px 0;
      }

      @include media-breakpoint-down(md) {
        margin: 130px 0 100px 0;
      }

      @include media-breakpoint-down(sm) {
        margin: 130px 0 40px 0;
      }

      .scroll-down {
        @include media-breakpoint-down(sm) {
          display: none !important;
        }
      }

      #affixx {
        width: 25% !important;
        z-index: 9;

        @include media-breakpoint-down(md) {
          width: 100% !important;
        }

        &.fixed {
          position: fixed;
          top: 200px !important;

          @include media-breakpoint-down(md) {
            position: relative;
            top: initial !important;
          }
        }

        &.ab-bottom {
          width: 100% !important;
          position: absolute;
          top: 0;
        }
      }

      .tags-property {
        display: inline-block;
        margin: 50px 15px 0 0;
        padding: 15px;
        background-color: $institutional;
        color: $white;
      }

      .block-property {
        @extend .extend-item-property;

        .item-property {
          @include media-breakpoint-down(md) {
            margin-top: 80px;
          }

          bdi.code-rif {
            @include media-breakpoint-down(md) {
              top: 55px;
              left: -40px;
            }

            @include media-breakpoint-down(sm) {
              left: 10px;
            }
          }

          figure.img-property {
            height: 500px !important;

            @include media-breakpoint-down(lg) {
              height: 400px !important;
            }

            @include media-breakpoint-down(sm) {
              width: 90%;
              height: 250px !important;
              margin-left: auto;
            }
          }

          .description-property {
            width: 100%;
            padding: 90px 60px 80px 60px;
            background-color: $institutional;
            margin-top: -60px;
            margin-left: -12%;
            position: relative;
            z-index: 2;

            @include media-breakpoint-down(lg) {
              padding: 60px 50px 50px 50px;
            }

            @include media-breakpoint-only(sm) {
              padding: 35px 25px 25px 25px !important;
            }

            @include media-breakpoint-down(sm) {
              width: 95%;
              padding: 25px 20px 15px 20px;
              margin-top: -40px;
              margin-left: 0;
            }

            p {
              @include media-breakpoint-down(md) {
                font-size: $rem-19px !important;
              }

              @include media-breakpoint-down(sm) {
                font-size: $rem-18px !important;
              }
            }
          }
        }
      }
    }

    section.info-extra-property {
      margin-bottom: 100px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }

      .block-square {
        @extend .extend-block-square;
      }
    }

    section.slider {
      margin-bottom: 100px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
      }

      .pl-35px {
        @include media-breakpoint-down(md) {
          padding-left: 15px;
        }
      }

      .col-md-5 {
        &.pl-35px {
          @include media-breakpoint-down(md) {
            padding-left: 30px;
          }
        }
      }

      hr {
        position: relative;
        left: -25px;

        @include media-breakpoint-down(sm) {
          left: 0;
        }
      }

      .col-custom-overflow {
        background-color: $white;
        z-index: 3;
      }

      .circle-view {
        width: 38px;
        height: 38px;
        line-height: 36px;
        background-color: $institutional;
        text-align: center;
        position: absolute;
        top: -20px;
        left: 25px;
        z-index: 2;

        @include border-radius(50%);

        a {
          color: $white;

          svg {
            stroke-width: 2;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: $black;
        }
      }

      .slider-single-property {
        position: relative;
        z-index: 4;

        @include media-breakpoint-down(sm) {
          margin-top: 100px !important;
        }

        hr.line {
          width: 70px;
          margin: 0;
          position: absolute;
          right: 130px;
          left: initial;
          top: -30px;
          border-width: 1px;
        }

        .swiper-pagination {
          width: 30%;
          height: 50px;
          position: absolute;
          top: -50px;
          right: 30px;
          left: initial;
          text-align: right;
          z-index: 1;

          span {
            font-size: $rem-22px;
            margin: 0 3px;

            &:last-child {
              margin-right: 0;
            }

            &.swiper-pagination-current {
              font-size: $rem-22px * 2;
              color: $institutional;
            }
          }
        }

        .swiper-controls {
          position: absolute;
          bottom: -30px;
          right: 15px;
          z-index: 2;

          .swiper-prev,
          .swiper-next {
            width: 70px;
            height: 70px;
            line-height: 70px;
            background-color: $white;
            text-align: center;

            img {
              width: 30px;
              height: 30px;
            }

            &:hover {
              cursor: pointer;
              background-color: $institutional;
            }
          }
        }

        .swiper-slider-single-property {
          @extend .extend-item-property;

          figure.img-property {
            height: 380px !important;

            @include media-breakpoint-down(lg) {
              height: 320px !important;
            }

            @include media-breakpoint-down(md) {
              height: 260px !important;
            }

            @include media-breakpoint-down(sm) {
              height: 250px !important;
            }
          }
        }
      }
    }

    section.map {
      margin-bottom: 100px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 80px;
      }

      iframe {
        width: 100%;
        height: 400px;
        position: relative;
        border: none;
        background-color: $grey8;

        @include media-breakpoint-down(lg) {
          height: 320px;
        }
      }
    }

    section.share {
      margin-bottom: 100px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 80px;
      }

      nav.share-social {
        span {
          &:hover {
            cursor: pointer;
          }
        }

        a, span {
          color: $black;
          margin-right: 20px;

          &:hover {
            color: $institutional;
          }

          svg {
            width: 35px;
            height: 35px;

            &#icon-pinterest {
              &:hover {
                stroke: $institutional;
              }
            }
          }
        }
      }
    }

    section.related-place-property {
      display: none;
      padding: 100px 0;

      @include media-breakpoint-down(sm) {
        padding: 80px 0;
      }

      .swiper-controls {
        .swiper-prev,
        .swiper-next {
          width: 60px;
          height: 35px;
          line-height: 35px;
          text-align: right;

          img {
            width: 35px;
            height: 35px;
          }

          &:hover {
            cursor: pointer;

            img {
              opacity: 0.6;
            }
          }
        }
      }

      .slider-related-place-property {
        padding-left: 30px;

        .swiper-slider-related-place-property {
          margin-top: 60px;

          @extend .extend-item-property;

          bdi.code-rif {
            top: -20px !important;
          }

          figure.img-property {
            height: 230px !important;

            @include media-breakpoint-down(sm) {
              height: 180px !important;
            }
          }

          hgroup.caption {
            width: 90%;
            margin-left: -30px !important;

            @include media-breakpoint-down(sm) {
              width: 100%;
              padding: 20px 24px !important;
            }

            h4 {
              font-size: $rem-22px;
            }

            h5 {
              @include media-breakpoint-down(sm) {
                font-size: $rem-17px;
              }
            }

            h6 {
              &.small {
                @include media-breakpoint-down(sm) {
                  font-size: 100%;
                }
              }

              span.zone, span.separator {
                @include media-breakpoint-down(sm) {
                  display: none;
                }
              }

              span.city {
                display: block;
              }
            }

            &:hover {
              h5 {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
