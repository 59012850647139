/*!
  *  BUTTON
*/

button, .btnn {
  width: auto;
  height: auto;
  padding: 10px 46px;
  text-align: center;
  color: $white;
  border: none;

  &.btnn-institutional {
    background-color: $institutional;

    @include transition(background-color 0.2s ease-in-out);

    &:hover {
      background-color: lighten($institutional, 6%);
    }
  }

  &.btnn-white {
    background-color: $white;

    @include transition(background-color 0.2s ease-in-out);

    &:hover {
      background-color: darken($white, 12%);
    }
  }
}
