/*!
  *  PANEL
*/

aside {
  &.panel-right {
    visibility: hidden;
    width: 40%;
    height: 100vh;
    padding: 80px 0;
    position: fixed;
    top: 0;
    right: -40%;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 4;

    @include transition(all 0.5s ease-in-out);

    &.is-open {
      visibility: visible;
      right: 0;
    }

    @include media-breakpoint-down(lg) {
      width: 60%;
      right: -60%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      right: -100%;
    }

    .close-panel {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        right: 0;
      }
    }

    form#panel_search,
    form#request_data {
      .cs-select .cs-options {
        background-color: $institutional;
      }

      .cs-skin-border .cs-options li span:hover {
        color: $white;
      }
    }

    // panel-search
    &[data-panel="panel-search"] {
      .swiper-controls {
        .swiper-prev,
        .swiper-next {
          width: 60px;
          height: 30px;
          line-height: 30px;
          text-align: right;
          cursor: pointer;

          img {
            width: 30px;
            height: 30px;
          }

          &:hover img {
            opacity: 0.6;
          }
        }
      }

      .block-slider {
        padding-left: 30px;

        .swiper-slider-featured-panel {
          margin-top: 60px;

          @extend .extend-item-property;

          bdi.code-rif {
            top: -20px !important;
          }

          figure.img-property {
            height: 140px !important;

            @include media-breakpoint-down(sm) {
              height: 180px !important;
            }
          }

          hgroup.caption {
            padding: 12px 24px !important;
            margin-top: -20px !important;
            margin-left: -30px !important;

            @include media-breakpoint-down(sm) {
              width: 100%;
              padding: 20px 24px !important;
            }

            h4 {
              font-size: $rem-16px;

              @include media-breakpoint-down(lg) {
                font-size: $rem-18px !important;
              }

              @include media-breakpoint-down(sm) {
                font-size: $rem-20px !important;
              }
            }

            h5 {
              font-size: $rem-14px;

              @include media-breakpoint-down(sm) {
                font-size: $rem-17px;
              }
            }

            h6 {
              &.small {
                @include media-breakpoint-down(sm) {
                  font-size: 100%;
                }
              }

              span.zone, span.separator {
                display: none;
              }
            }
          }
        }
      }
    }

    // panel-menu-mobile
    &[data-panel="panel-menu-mobile"] {
      nav.primary-menu-mobile {
        h2 {
          font-size: $rem-40px;

          a {
            color: $white;
          }
        }
      }

      .social-mobile {
        nav.icons-social {
          a{
            color: $white;

            svg {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}
