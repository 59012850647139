/*!
  *  TYPOGRAPHY
*/

h1,
h2,
h3,
h4,
h5,
h6,
span,
label,
abbr,
a {
  margin: 0;
  font-weight: normal;
}

h1 {
  font-size: $rem-38px;
  line-height: 1.3em;

  @include media-breakpoint-down(sm) {
    font-size: $rem-34px;
  }
}

h2 {
  font-size: $rem-32px;
  line-height: 1.3em;
}

h3 {
  font-size: $rem-28px;
  line-height: 1.3em;
}

h4 {
  font-size: $rem-24px;
  line-height: 1.4em;
}

h5 {
  font-size: $rem-18px;
  line-height: 1.5em;
}

h6 {
  font-size: $rem-16px;
  line-height: 1.5em;
}

p {
  padding: 0;
  font-size: $rem-22px;
  color: $black;
  font-weight: 400;
  line-height: 1.6em;
}

b,
strong {
  color: $black;
  font-weight: bold;
}

a {
  color: $institutional;
  display: inline-block;

  @include transition(color 0.2s ease-in-out);

  &:focus,
  &:hover {
    opacity: 1 !important;
    color: $black;
    text-decoration: none;
    outline: medium none;
    text-shadow: none;
  }

  &.text-underline {
    text-decoration: underline;
    text-decoration-skip: none;
  }
}

.link-icon-arrow {
  display: inline-block;

  i, svg, img {
    @include transition(all 0.2s ease-in-out);

    &.left {
      position: relative;
      left: 0;
    }

    &.right {
      position: relative;
      right: 0px;
    }
  }

  &:hover i,
  &:hover svg,
  &:hover img {
    &.left {
      left: -5px;
    }

    &.right {
      right: -5px;
    }
  }
}

blockquote {
  border-left: 4px solid $grey5 !important;
  font-size: $rem-18px !important;
  line-height: 1.3em;
  color: $black !important;
  margin: 20px 0 !important;
  padding: 0 15px;
}

abbr {
  &.quote {
    font-size: $rem-38px;
    line-height: 1.3em;

    img {
      width: 20px;
      height: 20px;
      vertical-align: super;
    }
  }
}

.line-height-1 {
  line-height: 1em !important;
}

.line-height-1-1 {
  line-height: 1.1em !important;
}

.line-height-1-2 {
  line-height: 1.2em !important;
}

.line-height-1-3 {
  line-height: 1.3em !important;
}

.line-height-1-4 {
  line-height: 1.4em !important;
}

.line-height-1-5 {
  line-height: 1.5em !important;
}

.line-height-1-6 {
  line-height: 1.6em !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.w-regular {
  font-weight: normal;
}

.w-bold {
  font-weight: bold;
}

.f-vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap;

  @include transform(rotate(-180deg));
}

.f-italic {
  font-style: italic;
}

.f-lowercase {
  text-transform: lowercase;
}

.f-capitalize {
  text-transform: capitalize;
}

.f-uppercase {
  text-transform: uppercase;
}

.f-small {
  font-size: $rem-13px !important;
  line-height: 1.5em;
}

.f-medium {
  font-size: $rem-20px !important;
  line-height: 1.4em;
}

.f-large {
  font-size: $rem-32px !important;
  line-height: 1.3em;
}

.f-big {
  font-size: $rem-18px * 2;

  @include media-breakpoint-down(lg) {
    font-size: $rem-18px * 2;
  }

  @include media-breakpoint-down(sm) {
    font-size: $rem-34px;
  }
}

.f-extra-big {
  font-size: $rem-26px * 2;

  // @include media-breakpoint-down(sm) {
  //   font-size: $rem-34px + $rem-10px;
  // }
}
