/*!
  *  MIXINS
*/

/* ---------------------------- Border radius ----------------------------------- */
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius:    $radius;
    -o-border-radius:      $radius;
    -ms-border-radius:     $radius;
    border-radius:         $radius;
}
/* ---------------------------- Border radius top ----------------------------------- */
@mixin border-radius-top($radius_top) {
    -webkit-border-top-right-radius: $radius_top;
    -moz-border-top-right-radius:    $radius_top;
    -o-border-top-right-radius:      $radius_top;
    -ms-border-top-right-radius:     $radius_top;
    border-top-right-radius:         $radius_top;
}
/* ---------------------------- Border radius top ----------------------------------- */
@mixin border-radius-bottom($radius_bottom) {
    -webkit-border-bottom-left-radius: $radius_bottom;
    -moz-border-bottom-left-radius:    $radius_bottom;
    -o-border-bottom-left-radius:      $radius_bottom;
    -ms-border-bottom-left-radius:     $radius_bottom;
    border-bottom-left-radius:         $radius_bottom;
}
/* ---------------------------- Transition  ----------------------------------- */
@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -ms-transition:     $transition;
    transition:         $transition;
}
/* ---------------------------- Transition duration  ----------------------------------- */
@mixin transition-duration($transition-duration) {
    -webkit-transition-duration: $transition-duration;
    -moz-transition-duration:    $transition-duration;
    -o-transition-duration:      $transition-duration;
    -ms-transition-duration:     $transition-duration;
    transition-duration:         $transition-duration;
}
/* ---------------------------- Transition delay  ----------------------------------- */
@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    -moz-transition-delay:    $transition-delay;
    -o-transition-delay:      $transition-delay;
    -ms-transition-delay:     $transition-delay;
    transition-delay:         $transition-delay;
}
/* ---------------------------- Transform ----------------------------------- */
@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform:    $transform;
    -o-transform:      $transform;
    -ms-transform:     $transform;
    transform:         $transform;
}
/* ---------------------------- Transform origin ----------------------------------- */
@mixin transform-origin($transform-origin) {
    -webkit-transform-origin:   $transform-origin;
    -moz-transform-origin:      $transform-origin;
    -o-transform-origin:        $transform-origin;
    -ms-transform-origin:       $transform-origin;
    transform-origin:           $transform-origin;
}
/* ---------------------------- Animation ----------------------------------- */
@mixin animation($animation) {
    -webkit-animation:   $animation;
    -moz-animation:      $animation;
    -o-animation:        $animation;
    -ms-animation:       $animation;
    animation:           $animation;
}
/* ---------------------------- Box-sizing box ----------------------------------- */
@mixin box-sizing($box) {
    -webkit-box-sizing: $box;
    -moz-box-sizing:    $box;
    -o-box-sizing:      $box;
    -ms-box-sizing:     $box;
    box-sizing:         $box;
}
/* ---------------------------- Box-shadow ----------------------------------- */
@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow:    $shadow;
    -o-box-shadow:      $shadow;
    -ms-box-shadow:     $shadow;
    box-shadow:         $shadow;
}
/* ---------------------------- Background cover ----------------------------------- */
@mixin background-size($background-size) {
    -webkit-background-size: $background-size;
    -moz-background-size:    $background-size;
    -o-background-size:      $background-size;
    -ms-background-size:     $background-size;
    background-size:         $background-size;
}
/* ---------------------------- Line-clam ----------------------------------- */
@mixin line-clamp($line-clamp) {
    -webkit-line-clam: $line-clamp;
    -moz-line-clam:    $line-clamp;
    -o-line-clam:      $line-clamp;
    -ms-line-clam:     $line-clamp;
    line-clam:         $line-clamp;
}
/* ---------------------------- Box-orient ----------------------------------- */
@mixin box-orient($box-orient) {
    -webkit-box-orient: $box-orient;
    -moz-box-orient:    $box-orient;
    -o-box-orient:      $box-orient;
    -ms-box-orient:     $box-orient;
    box-orient:         $box-orient;
}
/* ---------------------------- Box-orient ----------------------------------- */
@mixin text-size-adjust($adjust) {
    -webkit-text-size-adjust:   $adjust;
    -moz-text-size-adjust:      $adjust;
    -o-text-size-adjust:        $adjust;
    -ms-text-size-adjust:       $adjust;
    text-size-adjust:           $adjust;
}
/* ---------------------------- Rotate ----------------------------------- */
@mixin rotate($rotate) {
    -webkit-rotate:   $rotate;
    -moz-rotate:      $rotate;
    -o-rotate:        $rotate;
    -ms-rotate:       $rotate;
    rotate:           $rotate;
}
